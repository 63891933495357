<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="this.api"/>
        <v-skeleton-loader v-if="api.isLoading" ref="skeleton" type="table">

        </v-skeleton-loader>
        <v-card v-else>
            <v-toolbar class="white--text text-h5 mb-4" color="secondary">
                Companies Edit
            </v-toolbar>
            <v-row no-gutters>
                <v-col class="pa-3" v-if="companies!=null"> 
                    <div class="d-flex justify-start">
                        <v-text-field label="Company Name" v-model="companies.company_name" dense outlined>

                        </v-text-field>
                    </div>
                    <div class="d-flex justify-start">
                        <v-text-field label="Industry" v-model="companies.company_industry" dense outlined>

                        </v-text-field>
                    </div>
                    <div class="d-flex justify-start">
                        <v-textarea label="Address" v-model="companies.company_address" dense outlined>

                        </v-textarea>
                    </div>

                </v-col>
                <v-col class="pa-3">
                    <div class="d-flex justify-start">
                        <v-text-field label="Coupon" v-model="companies.company_coupon" dense outlined>
                        </v-text-field>
                    </div>
                    <div class="d-flex justify-start">
                        <v-text-field label="SSM No." v-model="companies.company_ssm" dense outlined>
                        </v-text-field>
                    </div>
                   
                    
                </v-col>
            </v-row>
            <div class="d-flex justify-end pa-5">

                <v-btn color="primary" plain>
                    Cancel
                </v-btn>
                <v-btn color="primary" @click="()=>{
                    this.isPending =true;
                }">
                    Confirm
                </v-btn>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';

export default{
    data: () => ({
        isPending: false,
        companies: null,
        users: null,
        isVerified: false,
        isVerifiedCustomValue: 'Verified',
        isNotVerifiedCustomValue: 'Not Verified',
        isEnable: false,
        isEnableCustomValue: 'Enable',
        isNotEnableCustomValue: 'Disable',
        AccountUserOptions:['Primary',"Secondary"],
        MainCategoryOptions : ["SmartCFO Client","TaxPOD Client","Internal Staff"],
        RoleOptions: ["User", 'Admin', "Instructor","Manager","Consultant","Tax Expert"],
        AAERoleOptions:["client","consultant","tax expert","admin","manager"],
        api: {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        }
    }),
    computed: {
        ComputeCompanyIsVerified(verified_at) {
            return (verified_at != null) ? true : false;
        },
        MappingSwitchIsVerified: {
            get(tempIsVerified) {
                return tempIsVerified == true ? this.isVerifiedCustomValue : this.isNotVerifiedCustomValue;
            },
            set(value) {
                this.isVerified = value === this.isVerifiedCustomValue;
            }
        },
        MappingSwitchIsEnable: {
            get() {
                return this.isEnable ? this.isEnableCustomValue : this.isNotEnableCustomValue;
            },
            set(value) {
                this.isEnable = value === this.isEnableCustomValue;
            },
        }
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getCompanyFullInformation") {
                let tempData = resp.data[0];
                let tempCompany = {
                    "company_name": tempData.company_name,
                    "company_id": tempData.company_id,
                    "company_address": tempData.address,
                    "company_ssm": tempData.ssm_no,
                    "company_industry": tempData.industry,
                    "company_branch": tempData.branch,
                    "company_subscription_type": tempData.subscription_type,
                    "company_coupon": tempData.coupon,
                };
                this.companies = tempCompany;
                // 
            }

            if(resp.class=="updateCompanyFullInformation"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update Company Detail";
            }
            // What I have don yesterday
            // Finish the active task module. With an adjustment from
            // Hanis design. Today got a new enhancement request from xin ying 
            //
            this.api.isLoading = false;
            this.api.isError = false;
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let fetchCompaniesDetailApi = this.fetchCompaniesDetail();
            this.$api.fetch(fetchCompaniesDetailApi);
        },
        fetchCompaniesDetail() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/company/full/client/id/" + this.$router.history.current.params.id;
            return tempApi;
        },
        validateInput() {
            this.isPending = true;
        },
        submit(){
            this.isPending = false;
            let formData = new FormData;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/company/full/client/id/"+this.$router.history.current.params.id;
            formData.append("company_name",this.companies.company_name);
            formData.append("company_address",this.companies.company_address);
            formData.append("company_coupon",this.companies.company_coupon);
            formData.append('company_ssm',this.companies.company_ssm);
            formData.append('company_industry',this.companies.company_industry);
            formData.append('company_branch',this.companies.company_branch);
            formData.append('subscription_type',this.companies.company_subscription_type);
            formData.append('company_coupon',this.companies.company_coupon);
            formData.append('causer_id',this.$store.getters.getUserId);

            tempApi.params = formData;

            this.$api.fetch(tempApi);

        },
        cancelSubmit(){
            this.isPending = false;
        },  
    },
    components: { AConfirmation,ASuccessFour }
}
</script>